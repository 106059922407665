import React from 'react'
import { useRouter } from 'next/router'
import { Button } from '@components/ui/buttons/Button2'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { MessageCircleMore } from 'lucide-react'
import { SocialConnectionButton } from './SocialConnectionButton'

interface ProfileActionButtonsProps {
  isAccountOwner: boolean
  onSharedProfile: () => void
  onSendMessage: () => void
  accountId?: string
  disablePrivateMessages?: boolean | null
}

export const ProfileActionButtons = ({
  isAccountOwner,
  onSharedProfile,
  onSendMessage,
  accountId,
  disablePrivateMessages,
}: ProfileActionButtonsProps) => {
  const router = useRouter()
  const { t } = useTypedTranslation()
  const { selectedAccount } = useAccountStore()

  if (isAccountOwner) {
    return (
      <div className="flex w-full flex-row items-center gap-4">
        <Button
          size="sm"
          variant={'default'}
          className="w-1/2 flex-1"
          onClick={async () =>
            await router.push(`/account/${accountId}/settings`)
          }
        >
          {t('text-profile-edit', { ns: 'common' })}
        </Button>

        <Button
          size="sm"
          variant={'default'}
          className="w-1/2 flex-1"
          onClick={onSharedProfile}
        >
          {t('text-profile-share', { ns: 'common' })}
        </Button>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-row items-center gap-4">
      <div className="w-1/2 flex-1">
        <SocialConnectionButton
          userId={selectedAccount?.id || ''}
          accountId={String(accountId)}
        />
      </div>

      {!disablePrivateMessages && (
        <Button
          size={'sm'}
          loading={!accountId}
          variant={'default'}
          className="w-1/2 flex-1"
          onClick={onSendMessage}
        >
          <MessageCircleMore className="mr-2 h-4 w-4" />
          Message
        </Button>
      )}
    </div>
  )
}
