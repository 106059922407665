import type { FC } from 'react'
import { Fragment } from 'react'
import { Button } from '@components/ui/buttons/Button2'
import { Clock, UserMinus, UserPlus } from 'lucide-react'

interface FollowButtonProps {
  isFollowing: boolean | null
  statusSlug?: string
  isLoading?: boolean
  isPending?: boolean
  onFollow: () => Promise<void>
  onUnFollow: () => Promise<void>
}

export const FollowButton: FC<FollowButtonProps> = ({
  isFollowing,
  statusSlug,
  isLoading = false,
  isPending = false,
  onFollow,
  onUnFollow,
}) => {
  if (isLoading) {
    return (
      <Button size="sm" loading variant={'default'} className="w-[100%]">
        <Clock className="mr-2 h-4 w-4" />
        Loading...
      </Button>
    )
  }

  if (isFollowing === null) {
    return <Fragment></Fragment>
  }

  if (isFollowing) {
    return (
      <Button
        onClick={onUnFollow}
        loading={isPending}
        size="sm"
        variant={'default'}
        className="w-[100%]"
      >
        <UserMinus className="mr-2 h-4 w-4" />
        Unfollow
      </Button>
    )
  }

  if (!isFollowing && statusSlug === 'pending') {
    return (
      <Button size="sm" disabled variant={'default'} className="w-[100%]">
        <Clock className="mr-2 h-4 w-4" />
        Awaiting response
      </Button>
    )
  }

  if (!isFollowing && statusSlug === 'not_friends') {
    return (
      <Button
        onClick={onFollow}
        loading={isPending}
        size="sm"
        variant={'default'}
        className="w-[100%]"
      >
        <UserPlus className="mr-2 h-4 w-4" />
        Follow
      </Button>
    )
  }

  return <Fragment></Fragment>
}
