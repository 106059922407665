import type { SearchableAccount } from '@sodium/shared-schemas'
import type { FC } from 'react'
import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  useAccountStore,
  useActivitiesStore,
} from '@sodium/shared-frontend-schemas'
import { useGoToConversation } from '@src/utils/router/useGoToConversation'
import { ParallelPostViewer } from './ParallelPostViewer'
import { ProfileConnectionsModal } from './ProfileConnectionsModal'
import { ProfileSpaceHeader } from './ProfileSpaceHeader'
import { TabContainer } from './TabContainer'

interface ProfileSpaceViewProps {
  accountId: string
  account: SearchableAccount | undefined | null
}

export const ProfileSpaceView: FC<ProfileSpaceViewProps> = memo(
  ({ accountId, account }) => {
    const { navigateToConversation } = useGoToConversation()
    const { selectedAccount } = useAccountStore()
    const [isAccountOwner, setIsAccountOwner] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const { setIndex, reset, index } = useActivitiesStore()
    const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false)

    const handlePostClick = ({ index }: { index: number }) => {
      setIndex(index)
      setIsDialogOpen(true)
    }

    const handleClose = () => {
      setIsDialogOpen(false)
    }

    const sendMessage = useCallback(() => {
      if (!account) {
        return
      }

      navigateToConversation({
        conversationId: account.id,
        account: {
          id: account.id,
          name: account.displayName,
          avatar: account.profilePicture,
        },
      })
    }, [account])

    const onShareProfile = useCallback(() => {
      if (!account) {
        return
      }
    }, [account])

    const onShowConnectionsModal = useCallback(() => {
      setIsConnectionsModalOpen(true)
    }, [])

    useEffect(() => {
      setIsAccountOwner(selectedAccount?.id === accountId)
      return () => reset()
    }, [accountId, selectedAccount])

    return (
      <div className="col-[1_/_span_12] row-[1_/_span_2] rounded-md bg-white dark:bg-[rgb(29,34,38)] max-[768px]:col-span-12 max-[768px]:row-[1_/_span_12] md:col-[1_/_span_12] lg:col-[1_/_span_8]">
        <ProfileSpaceHeader
          account={account}
          accountId={String(accountId)}
          isAccountOwner={isAccountOwner}
          sendMessage={sendMessage}
          onSharedProfile={onShareProfile}
          onShowConnectionsModal={onShowConnectionsModal}
          postsCount={0}
          followersCount={0}
          followingCount={0}
        />
        <TabContainer
          account={account}
          accountId={String(accountId)}
          handlePostClick={handlePostClick}
        />
        <ParallelPostViewer
          accountId={String(accountId)}
          currentIndex={index}
          isDialogOpen={isDialogOpen}
          onClose={handleClose}
        />
        <ProfileConnectionsModal
          open={isConnectionsModalOpen}
          onClose={() => setIsConnectionsModalOpen(false)}
          accountId={accountId}
          userId={selectedAccount?.id || ''}
        />
      </div>
    )
  },
)
