import type { FC } from 'react'
import React from 'react'
import { AccountLayout } from '@components/account/accountLayout'
import { AppLayout } from '@components/layout/AppLayout'
import { useIs } from '@src/hooks/useIs'

interface IProps {
  children: React.ReactNode
}

export const ProfileSpaceLayout: FC<IProps> = ({ children }) => {
  const { isMobile } = useIs()
  return (
    <AppLayout showFooter={false}>
      <AccountLayout expanded={isMobile} transparent>
        {children}
      </AccountLayout>
    </AppLayout>
  )
}
