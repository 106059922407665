import type { RouterOutputs } from '@sodium/shared-frontend-services'
import type { VirtualItem } from '@tanstack/react-virtual'
import type { FC } from 'react'
import React, { useCallback } from 'react'
import { api } from '@api/api'
import { FollowButton } from '@components/account/FollowButton'
import { AccountSearchResult } from '@components/account/home/AccountSearchResult'
import { VirtualFeed } from '@components/ui/VirtualFeed'

export type ActivityItem =
  RouterOutputs['backend']['posts']['getPostComments']['data'][0]

type ActivityAccount = Pick<
  ActivityItem['account'],
  | 'id'
  | 'displayName'
  | 'profilePicture'
  | 'title'
  | 'slug'
  | 'disableComments'
  | 'disablePrivateMessages'
>

export interface ActivityConnectionsItem {
  account: ActivityAccount
  isFollowing: boolean | null
  friendshipStatusSlug: string
}

interface ProfileConnectionsListProps {
  accountId: string
  userId: string
  onClose?: () => void
  scrollHeight: number
}

export const ProfileConnectionsList: FC<ProfileConnectionsListProps> = ({
  accountId,
  userId,
  onClose,
  scrollHeight,
}) => {
  const getAccountConnectionsHook = api.posts.useGetAccountConnections({
    accountId,
  })

  const {
    onFollowPressed,
    onUnFollowPressed,
    followAccountHook,
    unFollowAccountHook,
  } = api.hooks.useSocialConnection({
    accountId,
    userId,
    onRefetch: getAccountConnectionsHook.refetch,
  })

  const connections =
    getAccountConnectionsHook.data?.pages.flatMap((c) => c.data) || []

  const renderConnection = useCallback(
    ({ item }: { item: ActivityConnectionsItem; virtualItem: VirtualItem }) => (
      <AccountSearchResult
        account={item.account}
        onAvatarPressed={() => onClose?.()}
        accessoryRight={
          <FollowButton
            isFollowing={item.isFollowing}
            statusSlug={item.friendshipStatusSlug}
            isLoading={false}
            isPending={
              followAccountHook.isPending || unFollowAccountHook.isPending
            }
            onFollow={() => onFollowPressed(item.account.id)}
            onUnFollow={() => onUnFollowPressed(item.account.id)}
          />
        }
      />
    ),
    [connections],
  )

  return (
    <VirtualFeed
      height={scrollHeight}
      estimatedItemHeight={60}
      estimatedItemWidth={300}
      infiniteQuery={getAccountConnectionsHook}
      renderItem={renderConnection}
    />
  )
}
