import type { Post } from '@components/account/home/HomePosts'
import React, { useCallback } from 'react'
import { api } from '@api/api'
import { VirtualFeed } from '@components/ui/VirtualFeed'
import { useActivitiesStore } from '@sodium/shared-frontend-schemas'
import { AccountPost } from './AccountPost'

export const AccountPosts = ({
  accountId,
  onPostPressed,
  showAdvertisingPosts = false,
}: {
  accountId?: string
  onPostPressed?: (props: { index: number }) => void
  showAdvertisingPosts?: boolean
}) => {
  const { index } = useActivitiesStore()
  const getAccountFeedHook = api.posts.useAccountFeedInfiniteQuery({
    visibleAccountId: accountId,
  })

  const getAdvertisingAccountFeedHook =
    api.posts.useAdvertisingAccountFeedInfiniteQuery({
      enabled: showAdvertisingPosts,
    })

  const renderItem = useCallback(
    ({ item, virtualItem }: { item: Post; virtualItem: any }) => (
      <div key={virtualItem.key} className="w-full cursor-pointer">
        <AccountPost
          accountId={accountId}
          index={virtualItem.index}
          post={item}
          onPostPressed={onPostPressed}
        />
      </div>
    ),
    [accountId],
  )

  return (
    <VirtualFeed
      scrollToIndex={index}
      infiniteQuery={
        showAdvertisingPosts
          ? getAdvertisingAccountFeedHook
          : getAccountFeedHook
      }
      renderItem={renderItem}
      containerClassName="grid grid-cols-12 gap-0.5"
      itemClassName="overflow-hidden aspect-square col-span-4"
    />
  )
}

export default AccountPosts
