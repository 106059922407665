import type { Post } from '@components/account/home/HomePosts'
import { useCallback } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import { useActivitiesStore } from '@sodium/shared-frontend-schemas'
import { motion } from 'framer-motion'

export const AccountPost = ({
  accountId,
  index,
  post,
  onPostPressed,
}: {
  accountId?: string
  index?: number
  post: Post
  onPostPressed?: (props: { index: number }) => void
}) => {
  const { setIndex, reset } = useActivitiesStore()
  const router = useRouter()
  const asset = post?.originalPost?.assets?.[0] ?? post?.assets?.[0]

  const handlePress = useCallback(() => {
    if (accountId && (index === 0 || index)) {
      onPostPressed?.({ index })
    }
  }, [accountId, index, post.id, reset, setIndex, router])

  // If no asset, text only
  if (!asset) {
    return (
      <motion.div
        className="flex aspect-square items-center justify-center overflow-hidden bg-white"
        onClick={handlePress}
        whileHover={{ scale: 1.05 }}
      >
        <div className="line-clamp-3 w-full px-1 text-center">
          <RichTextDisplay
            content={post.originalPost?.content ?? post.content}
          />
        </div>
      </motion.div>
    )
  }

  // Images or files, we get the image
  if (asset.isImage || asset.isFile || asset.isVideo) {
    return (
      <motion.div onClick={handlePress} whileHover={{ scale: 1.05 }}>
        <div className="relative aspect-square overflow-hidden bg-slate-100">
          <Image
            src={asset.thumbnailUrl || asset.url}
            alt={asset.originalName}
            layout="fill"
            objectFit="cover"
          />
        </div>
      </motion.div>
    )
  }

  return <></>
}
