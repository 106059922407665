import type { FC } from 'react'
import React, { useState } from 'react'
import { DrawerDialog } from '@components/ui/dialog/DrawerDialog'
import { ProfileConnectionsList } from './ProfileConnectionsList'

interface ProfileConnectionsModalProps {
  open: boolean
  onClose: () => void
  accountId: string
  userId: string
}

const snapPoints = [0.6, 1]

export const ProfileConnectionsModal: FC<ProfileConnectionsModalProps> = ({
  open,
  onClose,
  accountId,
  userId,
}) => {
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  return (
    <DrawerDialog
      open={open}
      onClose={onClose}
      title={'Connections'}
      snapPoints={snapPoints}
      onVisibleScrollHeightChange={setScrollHeight}
    >
      <ProfileConnectionsList
        scrollHeight={scrollHeight}
        accountId={accountId}
        userId={userId}
        onClose={onClose}
      />
    </DrawerDialog>
  )
}
