import type { SearchableAccount } from '@sodium/shared-schemas'
import type { FC } from 'react'
import React, { Suspense } from 'react'
import { PostGridSkeleton } from '@components/account/home/PostGridSkeleton'
import { AccountExperience } from '@components/activities/AccountExperience'
import AccountPosts from '@components/activities/AccountPosts'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@goatlab/react-ui'
import { sodiumConfig } from '@src/sodiumConfig'
import { Grid3X3Icon, NotebookTextIcon } from 'lucide-react'

interface TabContainerProps {
  accountId: string
  account: SearchableAccount | undefined | null
  handlePostClick: (props: { index: number }) => void
}

export const TabContainer: FC<TabContainerProps> = ({
  accountId,
  account,
  handlePostClick,
}) => {
  return (
    <Tabs defaultValue="Posts" className="w-full bg-white">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger
          className={`gap-2 border-b-2 border-transparent bg-white data-[state=active]:border-[${sodiumConfig.colors.iconColor}]`}
          value="Posts"
        >
          <Grid3X3Icon color={sodiumConfig.colors.iconColor} />
          {'Posts'}
        </TabsTrigger>
        <TabsTrigger
          className={`gap-2 border-b-2 border-transparent bg-white data-[state=active]:border-[${sodiumConfig.colors.iconColor}]`}
          value="Experience"
        >
          <NotebookTextIcon color={sodiumConfig.colors.iconColor} />
          {'Experience'}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="Posts" className="mt-1">
        <Suspense fallback={<PostGridSkeleton />}>
          <AccountPosts
            accountId={String(accountId)}
            onPostPressed={handlePostClick}
          />
        </Suspense>
      </TabsContent>
      <TabsContent value="Experience">
        <AccountExperience account={account} />
      </TabsContent>
    </Tabs>
  )
}
