import type { SearchableAccount } from '@sodium/shared-schemas'
import React, { memo } from 'react'
import Link from 'next/link'
import { ProfileBackgroundImageUpload } from '@components/ui/forms/images/ProfileBackgroundImageUpload'
import { ProfilePictureUpload } from '@components/ui/forms/images/ProfilePictureUpload'
import {
  defaultBackgroundImg,
  defaultProfileImg,
} from '@components/ui/images/images'
import Text from '@components/ui/Text'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { formatNumberToCounter } from '@sodium/shared-frontend-schemas'
import { LinkIcon } from 'lucide-react'
import { ProfileActionButtons } from './ProfileActionButtons'

export const ProfileSpaceHeader = memo(
  ({
    account,
    accountId,
    isAccountOwner,
    sendMessage,
    onSharedProfile,
    onShowConnectionsModal,
  }: {
    account: SearchableAccount | undefined | null
    accountId: string
    isAccountOwner: boolean
    sendMessage: () => void
    onSharedProfile: () => void
    onShowConnectionsModal: () => void
    postsCount?: number
    followersCount?: number
    followingCount?: number
  }) => {
    const { t } = useTypedTranslation()
    return (
      <>
        <div>
          <ProfileBackgroundImageUpload
            backgroundImageUrl={
              account?.backgroundImage || defaultBackgroundImg
            }
            fallbackText={account?.displayName}
            isAccountOwner={isAccountOwner}
          />
          <div className="flex h-36 w-full items-center justify-between px-6">
            <ProfilePictureUpload
              isAccountOwner={isAccountOwner}
              imageUrl={account?.profilePicture || defaultProfileImg}
              fallbackText={account?.displayName}
              changeImageText={t('text-change', { ns: 'common' })}
              showUploadButton={false}
              className={`absolute z-20 -mt-32 h-[120px] w-[120px] rounded-full border-2 border-gray-200 dark:border-[rgb(29,34,38)] max-[1060px]:-mt-32 max-[1060px]:h-24 max-[1060px]:w-24 ${isAccountOwner ? 'cursor-pointer' : ''}`}
            />
            <div className="flex w-full max-w-md content-end justify-end gap-12 pb-12 pr-10 text-center">
              <div className="flex flex-col items-center">
                <Text className="text-xl font-bold">
                  {formatNumberToCounter(account?.postsCount)}
                </Text>
                <Text className="text-sm">Posts</Text>
              </div>
              <div
                className="flex cursor-pointer flex-col items-center"
                onClick={onShowConnectionsModal}
              >
                <Text className="text-xl font-bold">
                  {formatNumberToCounter(account?.connectionsCount)}
                </Text>
                <Text className="text-sm">Connections</Text>
              </div>
            </div>
          </div>
        </div>
        <div className="-mt-16 flex flex-col pl-4">
          <Text
            variant="heading"
            className="text-2xl font-bold dark:text-white max-[990px]:text-[18px]"
          >
            {account?.displayName}
          </Text>
          <Text className="font-medium">{account?.title}</Text>

          {account?.website && (
            <div className="-mt-3 flex flex-row items-center space-x-2">
              <LinkIcon size={15} />
              <Link href={account.website} target="_blank" rel="noopener">
                {account.website}
              </Link>
            </div>
          )}
        </div>
        <div className="space-x-4 p-4">
          <ProfileActionButtons
            isAccountOwner={isAccountOwner}
            accountId={accountId}
            onSendMessage={sendMessage}
            onSharedProfile={onSharedProfile}
            disablePrivateMessages={account?.disablePrivateMessages}
          />
        </div>
      </>
    )
  },
)
