import type { FC } from 'react'
import React from 'react'
import HomePosts from '@components/account/home/HomePosts'
import { Button } from '@components/ui/buttons/Button2'
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaHeader,
  CredenzaTitle,
  ScrollArea,
} from '@goatlab/react-ui'
import { X } from 'lucide-react'

interface ParallelPostViewerProps {
  accountId: string
  currentIndex?: number
  isDialogOpen: boolean
  onClose: () => void
  showAdvertisingPosts?: boolean
}

export const ParallelPostViewer: FC<ParallelPostViewerProps> = ({
  accountId,
  currentIndex = 0,
  isDialogOpen,
  onClose,
}) => {
  return (
    <Credenza open={isDialogOpen} onOpenChange={onClose}>
      <CredenzaContent className="mx-auto min-h-dvh w-full min-w-full max-w-full border-none bg-white px-0 backdrop-blur-sm">
        <CredenzaClose asChild>
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-2 z-10 inline-flex rounded-full bg-gray-200 p-2 shadow-md"
            onClick={onClose}
          >
            <X className="h-4 w-4" />
          </Button>
        </CredenzaClose>
        <CredenzaHeader className="flex w-full flex-col items-center justify-center">
          <CredenzaTitle>Posts</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody className="h-full w-full">
          <ScrollArea className="h-full max-h-[calc(100dvh-120px)] min-w-full flex-1 overflow-y-scroll">
            <HomePosts
              isViewable
              showStories={false}
              accountId={accountId}
              currentIndex={currentIndex}
              showHeaderInput={false}
              shouldScroll={true}
              showAdvertisingPosts={false}
            />
          </ScrollArea>
        </CredenzaBody>
      </CredenzaContent>
    </Credenza>
  )
}
