import type { ImageUploadProps } from '@api/uploads'
import type * as AvatarPrimitive from '@radix-ui/react-avatar'
import type { FC } from 'react'
import React, { useState } from 'react'
import { backendRequest } from '@api/trpc'
import { uploadService } from '@api/uploads'
import { useAuth } from '@components/auth/store/auth.store'
import { Button } from '@components/ui/buttons/Button2'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { defaultProfileImg } from '@components/ui/images/images'
import { ImageUpload } from '@components/ui/images/ImageUpload'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  useToast,
} from '@goatlab/react-ui'
import { cn } from '@src/utils/cn'
import { useMutation } from '@tanstack/react-query'

interface ProfilePictureUploadProps {
  imageUrl?: string | null
  fallbackText?: string | null
  changeImageText: string
  showUploadButton?: boolean
  isAccountOwner: boolean
}

export const ProfilePictureUpload: FC<
  ProfilePictureUploadProps &
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
> = ({
  imageUrl,
  fallbackText,
  changeImageText,
  showUploadButton = true,
  isAccountOwner,
  className,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { toast } = useToast()
  const { refreshAccount: refreshProfile, user } = useAuth()

  const { mutateAsync, isPending } = useMutation({
    async mutationFn(image: ImageUploadProps) {
      if (!user?.account?.id) {
        return
      }

      const { url } = await uploadService.uploadPublicFile(image)

      await backendRequest.backend.account.updateById.mutate({
        accountId: user?.account?.id,
        updatedAccount: {
          profilePicture: url,
        },
      })

      refreshProfile()
      toast({
        title: 'Profile picture updated',
        description: 'Your profile picture has been updated',
      })
      setIsDialogOpen(false)
    },
    onError(error: any) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  return (
    <div className="flex items-center">
      <div className="">
        <Avatar
          onClick={() => {
            if (!isAccountOwner) return
            setIsDialogOpen(!isDialogOpen)
          }}
          className={cn('mb-4 h-16 w-16', className)}
        >
          <AvatarImage src={imageUrl ?? defaultProfileImg} />
          <AvatarFallback>{fallbackText}</AvatarFallback>
        </Avatar>
      </div>
      <div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          {showUploadButton && isAccountOwner && (
            <Button
              onClick={() => {
                if (!isAccountOwner) return
                setIsDialogOpen(!isDialogOpen)
              }}
              variant="slim"
              color="primary"
              type="button"
            >
              {changeImageText}
            </Button>
          )}
          <DialogContent className="md:min-w-[650px]">
            <DialogHeader>
              <DialogTitle>Edit profile Picture</DialogTitle>
            </DialogHeader>
            <div className="flex-1">
              <ImageUpload
                onUploadButtonPressed={async (data) => {
                  if (!isAccountOwner) return
                  await mutateAsync(data)
                }}
                isLoading={isPending}
                availableAspects={[]}
                cropResizable={false}
                cropAspect="square"
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
