import type { FC } from 'react'
import { api } from '@api/api'
import { FollowButton } from '@components/account/FollowButton'

interface SocialConnectionButtonProps {
  accountId: string
  userId: string
}

export const SocialConnectionButton: FC<SocialConnectionButtonProps> = ({
  accountId,
  userId,
}) => {
  const {
    onFollowPressed,
    onUnFollowPressed,
    getSocialConnection,
    followAccountHook,
    unFollowAccountHook,
  } = api.hooks.useSocialConnection({ accountId, userId, enabled: true })
  const socialConnection = getSocialConnection.data

  return (
    <FollowButton
      isFollowing={socialConnection?.friendshipStatus ?? null}
      statusSlug={socialConnection?.friendshipStatusSlug}
      isLoading={getSocialConnection.isPending}
      isPending={followAccountHook.isPending || unFollowAccountHook.isPending}
      onFollow={() => onFollowPressed(accountId)}
      onUnFollow={() => onUnFollowPressed(accountId)}
    />
  )
}
