import type { ImageUploadProps } from '@api/uploads'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { backendRequest } from '@api/trpc'
import { uploadService } from '@api/uploads'
import { useAuth } from '@components/auth/store/auth.store'
import { ImageUpload } from '@components/ui/images/ImageUpload'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  toastSonner,
} from '@goatlab/react-ui'
import { useIs } from '@src/hooks/useIs'
import { useMutation } from '@tanstack/react-query'
import { CameraIcon } from 'lucide-react'

function useWindowWidth() {
  // Initialize state with undefined width to handle SSR
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined)

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowWidth
}

interface ProfileBackgroundImageUploadProps {
  backgroundImageUrl: string
  isAccountOwner: boolean
  fallbackText?: string | null
}

export const ProfileBackgroundImageUpload: FC<
  ProfileBackgroundImageUploadProps
> = ({ backgroundImageUrl, isAccountOwner }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { refreshAccount: refreshProfile, user } = useAuth()
  const { isMobile } = useIs()

  const { mutateAsync, isPending } = useMutation({
    async mutationFn(image: ImageUploadProps) {
      toastSonner.promise(
        (async () => {
          if (!user?.account?.id) {
            return
          }
          const { url } = await uploadService.uploadFile(image)
          await backendRequest.backend.account.updateById.mutate({
            accountId: user?.account?.id,
            updatedAccount: {
              backgroundImage: url,
            },
          })
        })(),
        {
          loading: 'Uploading background image...',
          position: isMobile ? 'top-center' : 'top-right',
          success: () => {
            refreshProfile()
            setIsDialogOpen(false)
            return 'Background image uploaded'
          },
          error: (error) => {
            return `There was an error uploading your background image ${error.message}`
          },
        },
      )
    },
  })

  const windowWidth = useWindowWidth() || 0
  const maxWidth = Math.min(windowWidth, 1440)
  const screenWidth = isMobile ? maxWidth : (maxWidth / 4) * 2

  const aspectRatio = 1584 / 396
  const height = (screenWidth || 0) / aspectRatio

  return (
    <div>
      <div
        className="flex"
        style={{
          height,
          // marginBottom: -profilePictureHeight / 2,
          width: '100%',
        }}
      >
        <Image
          width={1400}
          height={1000}
          src={backgroundImageUrl}
          alt=""
          className="static z-10 min-h-16"
          style={{
            height: '100%',
            width: '100%',
          }}
        />

        {isAccountOwner && (
          <div className="z-20 -ml-12 mt-5 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white">
            <CameraIcon
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              className="h-5 w-5 text-gray-600"
            />
          </div>
        )}
      </div>
      <div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="md:min-w-[650px]">
            <DialogHeader>
              <DialogTitle>Edit Background Image</DialogTitle>
            </DialogHeader>
            <div className="flex-1">
              <ImageUpload
                onUploadButtonPressed={async (data) => {
                  if (!isAccountOwner) return
                  await mutateAsync(data)
                }}
                isLoading={isPending}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
